import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className='app__navbar'>
      <ul className='app__navbar-links'>
        {['home', 'skills', 'work', 'contact', 'resume'].map(
          (item) => {
            if (item !== 'resume') return (
              <li className='app__flex p-text' key={`link-${item}`}>
                <div />
                <a href={`#${item}`}>{item}</a>
              </li>
            )
            else return (
              <li className='app__flex p-text' key={`link-${item}`}>
                <div />
                <a href={'https://drive.google.com/file/d/1GjO7p0-UinK0mWK_ZCiV0a7J9X9H84Mr/view?usp=sharing'}>{item}</a>
              </li>
            )
          }
        )}
      </ul>

      <div className='app__navbar-menu'>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'skills', 'work', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
              <li key={'github-link'} id="github-link">
                <a href="https://github.com/rssebastian" onClick={() => setToggle(false)}>
                  Github
                </a>
              </li>
              <li key={'linkedin-link'} id="linkedin-link">
                <a href="https://www.linkedin.com/in/rssebastian/" onClick={() => setToggle(false)}>
                  LinkedIn
                </a>
              </li>
              <li key={'resume-link'} id="resume-link">
                <a href="https://drive.google.com/file/d/1GjO7p0-UinK0mWK_ZCiV0a7J9X9H84Mr/view?usp=sharing" onClick={() => setToggle(false)}>
                  Resume
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

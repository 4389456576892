import React, {useState} from 'react';

import {images} from '../../constants';
import {AppWrap, MotionWrap} from '../../wrapper';
import {client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const {username, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  }

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type: 'contact',
      name: username,
      email: email,
      message: message
    }

    client.create(contact)
      .then(() => {
        setLoading(false)
        setIsFormSubmitted(true)
      }).catch(err => console.error(err));
  }

  return (
    <>
      <h2 className="head-text">I'm open for any new opportunities,  so send me a message! 😊</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:sebastiansryan@gmail.com" className='p-text'>sebastiansryan@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +1 (951) 526-5797" className='p-text'>+1 (951) 526-5797</a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input type="text" className="p-text" placeholder='Your Name' name="username" value={username} onChange={handleChangeInput}/>
          </div>
          <div className="app__flex">
            <input type="email" className="p-text" placeholder='Your Email' name="email" value={email} onChange={handleChangeInput}/>
          </div>
          <div>
            <textarea className="p-text" placeholder="Your Message" name="message" value={message} onChange={handleChangeInput} />
          </div>
          <button type="button" className='p-text' onClick={handleSubmit}>{loading ? 'Sending...':'Send Message'}</button> 
        </div>
      ) : (
        <div>
          <h3 className='head-text'>Thank you for getting in touch!</h3>
        </div>
      )} 
    </>
  );
};

export default AppWrap(MotionWrap(Footer, 'app__footer'), 'contact','app__whitebg');

import React from 'react';
import { BsGithub, BsNewspaper } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href="https://github.com/rssebastian">
        <div>
          <BsGithub />
        </div>
      </a>
      <a href="https://www.linkedin.com/in/rssebastian/">
        <div>
          <FaLinkedin />
        </div>
      </a>
      <a href="https://drive.google.com/file/d/1GjO7p0-UinK0mWK_ZCiV0a7J9X9H84Mr/view?usp=sharing">
        <div>
          <BsNewspaper />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;

import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper/';
import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 1, delayChildren: 1 }}
        className='app__header-info'
      >
        <div className='app__flex app__header-description'>
          <h1 className='head-text' style={{marginBottom: '2.5rem'}}>Hello, World! I'm Ryan!</h1>
          <p className='p-text'>
            I'm a software engineer based in the Bay Area, with experience in both front and back-end development. I love making things work and figuring out why when things don't. 🤓
          </p>
        </div>
        <div className="app__flex app__header-image">
          <img src={images.profile} alt="profile pic" />
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, 'home');
